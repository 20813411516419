<template>

    <v-card-title class="dottedTitle" id="tableHeader"  style="min-width: 600px"
      >{{ pageTitle }}
      <span  v-if="$vuetify.breakpoint.mdAndUp" class="ml-3" style="font-weight: 400">
        {{ currentTable.additonalTitleInfo }}
      </span>
    </v-card-title>

</template>
<script>
export default {
  props: ["currentTable", "pageTitle"],
};
</script>
<style scoped>
.dottedTitle {
  display: inline-block;
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
</style>